import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Configure from "../components/Configure";

const ConfigurePage = () => {
  return (
    <Container fluid className="vh-100 mb-5">
      <Row>
        <Col className="h-100 w-100 col-12">
          <Configure />
        </Col>
      </Row>
    </Container>
  );
};

export default ConfigurePage;
