import React, { useEffect, useState } from "react";
import { Nav, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faGear,
  faRightFromBracket,
  faSquarePollHorizontal,
  faUser,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../static/img/sa3_logo_mini.png";
import { supabase } from "../lib/supabaseClient";
import "../static/css/dashboard.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSurveyProvider } from "../contexts/SurveyContext";
import useProfileProvider from "../contexts/ProfileContext";

export const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { surveyCompleted } = useSurveyProvider();
  const [page, setPage] = useState("profile");

  const { isManager, id } = useProfileProvider();

  const signoutClick = () => {
    supabase.auth.signOut().then(() => navigate("/"));
  };

  useEffect(() => {
    setPage(location.pathname.replace("/", ""));
  }, [location]);

  return (
    <>
      <Col className="d-flex col-12 col-sm-3 col-xl-2 px-0 bg-lead d-flex sticky-top pr-0 mr-0">
        <div
          className="w-100 bg-lead d-flex flex-sm-column 
		  flex-row flex-grow-1 align-items-center 
		  align-items-sm-start pl-3 pt-2 pr-0 mr-0 text-white"
          style={{ paddingLeft: "15px" }}
        >
          <div className="w-100 d-flex align-items-center align-items-sm-start justify-content-center ">
            <img
              className="fs-5 p-1 w-sm-25 w-50"
              src={logo}
              alt="company logo"
            ></img>
          </div>
          <Nav
            key={surveyCompleted}
            className="w-100 pr-0 mr-0 nav nav-pills nav-flush flex-sm-column 
			flex-row flex-nowrap me-auto mb-auto mr-auto text-start 
			align-items-sm-start align-items-center text-white "
          >
            <hr className="border-0" />
            <Nav.Item
              className={page === "configure" ? "full-nav-item p-2" : "p-2"}
            >
              <Nav.Link
                className="px-sm-0 px-2"
                as={Link}
                to="/configure"
                onClick={() => setPage("configure")}
              >
                <FontAwesomeIcon
                  className={
                    page === "configure"
                      ? "selected-icon fs-5"
                      : "unselected-icon fs-5"
                  }
                  icon={faGear}
                />
                <span
                  className="ms-1 d-none d-sm-inline"
                  style={
                    page === "configure"
                      ? { color: "#163c5d" }
                      : { color: "white" }
                  }
                >
                  Configure
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item
              className={page === "profile" ? "full-nav-item p-2" : "p-2"}
            >
              <Nav.Link
                className="px-sm-0 px-2"
                as={Link}
                to="/profile"
                disabled={surveyCompleted ? false : true}
                onClick={() => setPage("profile")}
              >
                <FontAwesomeIcon
                  className={
                    page === "profile" && id
                      ? "selected-icon fs-5" 
                      : "unselected-icon fs-5"
                  }
                  color={!id && 'darkgray'}
                  icon={faUser}
                />
                <span
                  className={
                    surveyCompleted
                      ? "ms-1 d-none d-sm-inline"
                      : "ms-1 d-none d-sm-inline disabled-item"
                  }
                  style={
                    page === "profile"
                      ? { color: "#163c5d" }
                      : { color: "white" }
                  }
                >
                  Profile
                </span>
                {!surveyCompleted && (
                  <FontAwesomeIcon
                    className="ms-5 d-none d-sm-inline pull-right"
                    icon={faLock}
                    color={"#ffb236"}
                  />
                )}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item
              className={page === "assessment" ? "full-nav-item p-2" : "p-2"}
            >
              <Nav.Link
                className="px-sm-0 px-2"
                as={Link}
                to="/assessment"
                disabled={id ? false : true}
                onClick={() => setPage("assessment")}
              >
                <FontAwesomeIcon
                  className={
                    page === "assessment"
                      ? "selected-icon fs-5"
                      : "unselected-icon fs-5"
                  }
                  icon={faSquarePollHorizontal}
                />
                <span
                  className={
                    id
                      ? "ms-1 d-none d-sm-inline"
                      : "ms-1 d-none d-sm-inline disabled-item"
                  }
                  style={
                    page === "assessment"
                      ? { color: "#163c5d" }
                      : { color: "white" }
                  }
                >
                  Assessment{" "}
                </span>
                {!id && (
                  <FontAwesomeIcon
                    className="ms-5 d-none d-sm-inline pull-right"
                    icon={faLock}
                    color={"#ffb236"}
                  />
                )}
              </Nav.Link>
            </Nav.Item>
           {isManager &&  <Nav.Item className={page === "team" ? "full-nav-item p-2" : "p-2"}>
              <Nav.Link
                className="px-sm-0 px-2"
                as={Link}
                to="/team"
                onClick={() => setPage("team")}
              >
                <FontAwesomeIcon
                  className={
                    page === "team"
                      ? "selected-icon fs-5"
                      : "unselected-icon fs-5"
                  }
                  icon={faPeopleGroup}
                />
                <span
                  className="ms-1 d-none d-sm-inline"
                  style={
                    page === "team" ? { color: "#163c5d" } : { color: "white" }
                  }
                >
                  Team
                </span>
              </Nav.Link>
            </Nav.Item>}
            <Nav.Item className="p-2 bottom-nav-item mt-sm-5">
              <Nav.Link className="px-sm-0 px-2" onClick={() => signoutClick()}>
                <FontAwesomeIcon
                  className="fs-5"
                  icon={faRightFromBracket}
                  color={"white"}
                />
                <span className="ms-1 d-none text-white d-sm-inline">
                  Logout
                </span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </Col>
    </>
  );
};
