import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import logo from "../static/img/sa3_logo_mini.png";

 const LoadingPage = () => {
  return (
    <Container className="vh-100 p-0 bg-lead sign-in-container" fluid>
      <Row className="h-75  mt-5 justify-content-md-center align-items-center">
        <Col className="col-lg-6 col-md-6 col-sm-4 col-xs-2 justify-content-md-center align-items-center">
          <Card className="mx-auto  h-100 border-0 bg-transparent  rounded-0">
            <Card.Header className="border-0 bg-transparent text-center text-white">
              <div className="mx-auto">
                <img className="shimmer" src={logo}></img>
              </div>
            </Card.Header>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoadingPage