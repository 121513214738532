import React from "react";
import { Container, Row, Col, ButtonGroup, Button } from "react-bootstrap";
import TeamProfile from "../components/profile/TeamProfileSkills";

const TeamProfilePage = () => {
  return (
      <Container fluid className="vh-100 mb-5">
        <Row>
          <Col className="h-100 w-100 col-12">
              <TeamProfile />
          </Col>
        </Row>
      </Container>
  );
};

export default TeamProfilePage;