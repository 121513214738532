import React from "react";
import QuestionFrame from "../components/survey/QuestionFrame";
import { Carousel } from "react-bootstrap";
import "../static/css/survey.css";
import { useSurveyProvider } from "../contexts/SurveyContext";
import EndingFrame from "./survey/EndingFrame";
import StartingFrame from "./survey/StartingFrame";
import SubmitFrame from "./survey/SubmitFrame";
import LearningFrame from "./survey/LearningFrame";
const Survey = () => {
  const {
    index,
    setIndex,
    survey,
    surveySubmitted,
    skillData,
    surveyCompleted,
  } = useSurveyProvider();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      {!surveyCompleted ? (
        <Carousel
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}
          variant="dark"
          className="h-100"
          controls={false}
          indicators={false}
          touch={false}
        >
          <Carousel.Item className="h-100">
            <StartingFrame />
          </Carousel.Item>
          {skillData.map((props, i) => {
            return (
              <Carousel.Item className="h-100">
                <QuestionFrame
                  props={{ ...props, sectionid: i, questionid: props.id }}
                />
              </Carousel.Item>
            );
          })}
          <Carousel.Item className = "h-100"> 
            <LearningFrame />
          </Carousel.Item>
          <Carousel.Item className="h-100">
            {!surveySubmitted ? <SubmitFrame /> : <EndingFrame />}
          </Carousel.Item>
        </Carousel>
      ) : (
        <EndingFrame />
      )}
    </>
  );
};

export default Survey;
