import "./index.css";
import { useState, useEffect } from "react";
import { supabase } from "./lib/supabaseClient";
import Auth from "./components/Auth";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Wrapper from "./Wrapper";
import ProfilePage from "./pages/ProfilePage";
import SurveyPage from "./pages/SurveyPage";
import useAuthProvider from "./contexts/AuthContext";
import LoadingPage  from './pages/LoadingPage'; 
import ConfigurePage from './pages/ConfigurePage';
import NotFoundPage from "./pages/NotFoundPage";
import ManagerPage from "./pages/ManagerPage";
import { useSurveyProvider } from "./contexts/SurveyContext";
import TeamProfilePage from "./pages/TeamProfilePage";
import useProfileProvider from "./contexts/ProfileContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";

export default function App() {
  // const [session, setSession] = useState(null);

  // useEffect(() => {
  //   supabase.auth.getSession().then(({ data: { session } }) => {
  //     setSession(session);
  //   });

  //   supabase.auth.onAuthStateChange((_event, session) => {
  //     setSession(session);
  //   });
  // }, []);

  const { session, loading } = useAuthProvider();
  const { surveyCompleted } = useSurveyProvider();
  const { id, teamMember } = useProfileProvider(); 
  const [showMobileWarning, setShowMobileWarning] = useState(false)

useEffect(() => {
  if(window.innerWidth <= 800)
    setShowMobileWarning(true)
}, [])
  

const Warning = () => {
  return (<Container fluid>
    <div className = "mt-5 mx-auto p-5" style={{backgroundColor: '#163c5d', color: 'white'}}><FontAwesomeIcon color={'#ffb236'} icon={faWarning} />
      <span className = "m-2 d-inline">The SA3 application does not support usage on a mobile device. </span>
      <br></br> <br></br>
      <span> Please log into the application using your laptop or desktop computer.</span>{" "}
      <span>If you feel that this message is in error, please reach out to help@sa3.ec3co.com with your concerns.</span>
    </div>
  </Container>

  )
}
  return (
    showMobileWarning ? <Warning /> : 
    <BrowserRouter>
      {loading ? (
        <LoadingPage />
      ) : !session && !loading ? (
        <Routes>
          <Route path="*" element={<Auth />} />
          <Route path="/login" element={<Auth />} />
          <Route element={<NotFoundPage />} />
        </Routes>
      ) : (
        <Wrapper
          children={
            <Routes>
              <Route exact path="/" element={<ConfigurePage />} />
              <Route exact path="/profile" element={surveyCompleted ? <ProfilePage /> : <Navigate to="/assessment" />} />
              <Route exact path="/assessment" element={id ? <SurveyPage /> : <Navigate to="/configure"/>} />
              <Route exact path="/configure" element={<ConfigurePage />}/>
              <Route exact path="/team" element={<ManagerPage />} />
              <Route exact path="/team/profile" element={teamMember ? <TeamProfilePage /> : <Navigate to = "/team" />}  />
              <Route element={<NotFoundPage />} />
            </Routes>
          }
        />
      )}
    </BrowserRouter>
  );
}
