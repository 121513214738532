import React, { useContext, useState, useEffect } from "react";
import { supabase, toTimestamp } from "../lib/supabaseClient";
import useAuthProvider from "./AuthContext";
import { useSurveyProvider } from "./SurveyContext";

const ProfileContext = React.createContext();

export function ProfileContextProvider({ children }) {
  const [id, setId] = useState(null);
  const [job_title, setJobTitle] = useState(null);
  const [job_family, setJobFamily] = useState(null);
  const [job_level, setJobLevel] = useState(null);
  const [assessmentComplete, setAssessmentComplete] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [managerId, setManagerId] = useState(null);
  const [full_name, setFullName] = useState(null);
  const [skills_summary, setSkillsSummary] = useState([]);
  const [skills_interest, setSkillsInterest] = useState([]);
  const [directReportData, setDirectReportData] = useState([]);
  const [teamMember, setTeamMember] = useState(null);
  const [setUpError, setSetUpError] = useState(false);
  const [directReportCount, setDirectReportCount] = useState(0);
  const [team, setTeam] = useState(null)

  const { session, loading } = useAuthProvider();

  const { response_id, surveySubmitted, surveyCompleted } = useSurveyProvider();

  const getSkillsSummary = async () => {
    let { data, error, status } = await supabase
      .from("skills_summary")
      .select(
        `id, skill_title, skill_level_recoded, skill_interest, skill_applicable, skill_category, skill_answer_count`
      )
      .eq("response_id", response_id);

    if (error) {
      //   console.log("skills_summary error", error);
      return;
    } else {
      //   console.log("skills summary", data);
      setSkillsSummary(data);
    }
  };

  useEffect(() => {
    const getEmployeeCount = async () => {
      let { data, count, error } = await supabase
        .from("org_taxonomy")
        .select("*", { count: "exact", head: true })
        .eq("manager", id);
      setDirectReportCount(count);
    };

    const getTeam = async () => {
      let { data, error, status } = await supabase
        .from("org_taxonomy")
        .select(`full_name, job_title, emplid`)
        .eq("manager", id);
      setTeam(data.map(d => { return {name: d.full_name, title: d.job_title, emplid: d.emplid}}));
    };

    if (id) {
      getEmployeeCount();
      getTeam();
    }
  }, [id]);


  useEffect(() => {
    const getData = async () => {
      let { data, error, status } = await supabase
        .from("profiles")
        .select(
          `id, emplid, job_title, job_family, job_level, full_name, is_manager`
        )
        .eq("id", session?.user.id)
        .single();

      if (error) {
        console.log(error);
        return;
      }

      setId(data?.emplid || null);
      setJobTitle(data?.job_title ?? null);
      setFullName(data?.full_name ?? null);
      setIsManager(data?.is_manager ?? null);
      setJobFamily(data?.job_family ?? null);
      setJobLevel(data?.job_level ?? null);
    };

    const getSkillsOfInterest = async () => {
      let { data, error, status } = await supabase
        .from("skill_response_answers")
        .select(
          `skill_statement_id, skill_statements (
				skill_statement, skill_group_id
			)`
        )
        .eq("skill_response_id", response_id)
        .eq("skill_interest", true);

      if (error) {
        console.log(error);
      }

      setSkillsInterest(
        data.map((d) => {
          return {
            skill_group_id: d.skill_statements.skill_group_id,
            skill: d.skill_statements.skill_statement,
            statement_id: d.skill_statement_id,
          };
        })
      );
    };

    // const getSkillIds = async() => {
    // 	let { data, error, status } = await supabase
    // }

    if (!loading && session?.user) {
      getData();
      getSkillsSummary();
      getSkillsOfInterest();
    }

    if (surveySubmitted && session?.user) {
      getSkillsSummary();
    }

    // setUser(session?.user ?? null);
  }, [session, loading, surveyCompleted]);

  //   useEffect(() => {
  // 	getSkillsSummary();
  //   }, [surveySubmitted, surveyCompleted])

  const onSubmitId = async (userId) => {
    let {
      data: orgData,
      error: orgError,
      status: orgStatus,
    } = await supabase
      .from("org_taxonomy")
      .select(
        `emplid, job_title, job_family, job_level, full_name, is_manager, manager`
      )
      .eq("emplid", userId)
      .single();

    if (orgError) {
      //   console.log("organization table error: ", orgError);
      setSetUpError(true);
      return;
    }

    const timestamp = new Date().toISOString();
    const { error } = await supabase
      .from("profiles")
      .update({
        emplid: userId,
        updated_at: timestamp,
        full_name: orgData?.full_name ?? null,
        job_title: orgData?.job_title ?? null,
        job_family: orgData?.job_family ?? null,
        job_level: orgData?.job_level ?? null,
        is_manager: orgData?.is_manager ?? null,
        manager_id: orgData?.manager ?? null,
      })
      .eq("id", session?.user?.id);

    const { error: responseError } = await supabase
      .from("responses")
      .update({
        manager_id: orgData.manager ?? null,
      })
      .eq("id", session?.user?.id);

    if (error || responseError) {
      console.log(error);
      console.log(responseError);
      setSetUpError(true);
    } else {
      setSetUpError(false);
      setId(userId);
      !full_name && setFullName(orgData?.full_name ?? null);
      !job_title && setJobTitle(orgData?.job_title ?? null);
      !job_family && setJobFamily(orgData?.job_family ?? null);
      !job_level && setJobLevel(orgData?.job_level ?? null);
      !isManager && setIsManager(orgData?.is_manager ?? null);
      !managerId && setManagerId(orgData?.manager ?? null);
    }
  };

  const onUpdateProfile = async () => {
    const timestamp = new Date().toISOString();
    const { error } = await supabase
      .from("profiles")
      .update({
        updated_at: timestamp,
        full_name: full_name ?? null,
        job_title: job_title ?? null,
        job_family: job_family ?? null,
        job_level: job_level ?? null,
      })
      .eq("id", session?.user?.id);

    if (error) {
      console.log(error);
    }
  };

  const getTeamResults = async () => {
    let {
      data: responseData,
      error: responseError,
      status: responseStatus,
    } = await supabase
      .from("responses")
      .select(`response_id, completed_at`)
      .eq("manager_id", id)
      .eq("is_completed", true);

    if (responseData.length > 0) {
      let { data, error, status } = await supabase
        .from("skills_summary")
        .select(
          `id, skill_title, skill_level_recoded, skill_interest, skill_applicable, skill_category, response_id, full_name, job_title, skill_answer_count, emplid`
        )
        .in("response_id", [responseData.map((r) => r.response_id)]);

      if (error) {
        return;
      } else {
        let dr_data = responseData.map((r) => {
          let d = data.filter((d) => d.response_id == r.response_id);

          return {
            emplid: d[0].emplid,
            name: d[0].full_name,
            title: d[0].job_title,
            compeleted: r.completed_at,
            results: d,
          };
        });
        setDirectReportData(dr_data);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getTeamResults();
    }
  }, [id, session, loading]);

  return (
    <ProfileContext.Provider
      value={{
        id,
        job_title,
        setJobTitle,
        job_family,
        setJobFamily,
        job_level,
        setJobLevel,
        full_name,
        setFullName,
        assessmentComplete,
        isManager,
        onSubmitId,
        onUpdateProfile,
        skills_summary,
        skills_interest,
        directReportData,
        teamMember,
        setTeamMember,
        setUpError,
        directReportCount,
        team
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export default function useProfileProvider() {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
