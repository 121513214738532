import React from "react";
import { Card, Row, Col, Placeholder } from "react-bootstrap";
import "../../static/css/profile.css";
import useProfileProvider from "../../contexts/ProfileContext";

export const EmployeeDetails = () => {
  const { job_family, job_level, job_title } = useProfileProvider();

  return (
    <Row className="justify-content-md-center">
      <Col className="col-12 col-md-4 h-100 ">
        <Card className="teal-light border-0">
          <Card.Body>
            <p>
              <strong>Job Family:</strong>
            </p>
            <p> {job_family ?? <Placeholder xs={6} />} </p>
          </Card.Body>
        </Card>
      </Col>
      <Col className="mt-1 mt-md-0 col-12 col-md-4 h-100 ">
        <Card className="teal-light border-0">
          <Card.Body>
            <p>
              <strong>Job Level:</strong>
            </p>
            <p> {job_level ?? <Placeholder xs={6} />} </p>
          </Card.Body>
        </Card>
      </Col>
      <Col className="col-12 col-md-4 h-100 ">
        <Card className="mt-1 mt-md-0 teal-light border-0">
          <Card.Body>
            <p>
              <strong>Job Position:</strong>
            </p>
            <p> {job_title ?? <Placeholder xs={6} />} </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
