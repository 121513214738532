import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useSurveyProvider } from "../../contexts/SurveyContext";

export const MCQuestion = () => {
  const { setAnswer } = useSurveyProvider();
  const [values, setValues] = useState([]);

  const selectItem = (val) => {
    if (values.includes(val)) {
      let v = values.filter(v => v !== val);
      setValues(v);
    } else {
      setValues([...values, val]);
    }
  };

  const prompts = [
    {
      id: "A",
      description:
        "I communicate my priorities when appropriate and prefer short and to-the-point interactions.",
    },
    { id: "B", description: "I am often surprised by other’s reactions to my proposals." },
    { id: "C", description: "I spend significant time preparing for and curating my communications based on the audience." },
    { id: "D", description: "I am aware of the varying communication styles and preferences." },
    { id: "E", description: "I prepare my communications in advance based on the varying communication styles and preferences of my audience." },
    { id: "F", description: "I start my presentations with the end goal in mind and tailor the message to the audience." },
    {
      id: "G",
      description: "I believe how a story is formulated and delivered is important for achieving results.",
    },
  ];

  return (
    <>
      {prompts.map((t) => {
        return (
          <Row className="mt-3 w-100 ">
            <Col className="">
              <Card
                onClick={() => {
                  selectItem(t.id);
                  setAnswer(t.label);
                }}
                className={
                  values.includes(t.id)
                    ? "mx-auto selected-card h-100 w-100 w-md-50 p-2 m-2"
                    : "mx-auto selectable-card h-100 w-100 p-2 m-2"
                }
              >
                <Card.Text className="text-start">
                  <span className="responsive-text d-inline">{t.id}. </span>
                  <p className="responsive-font d-inline" >
                    {t.description}
                  </p>
                </Card.Text>
              </Card>
            </Col>
          </Row>
        );
      })}
    </>
  );
};
