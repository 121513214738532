import React, { useState } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import { faBan, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "../../static/img/gm_blue.png";
import AccordionCheckBox from "./AccordionChecks";
import ProgressBar from "./ProgressBar";
const LearningFrame = () => {
  const { handleNextButton, handleBackButton, percent, results, setResults } =
    useSurveyProvider();

  const [interested, setInterested] = useState([]);

  const handleClick = (e) => {
    e.preventDefault();
	updateInterested(results, interested)
	
    handleNextButton();
  };


  const updateInterested = (results, updatedSubquestions) => {
    // Create a map for easier lookup
    const updatedSubquestionsMap = updatedSubquestions.reduce((acc, item) => {
      acc[item.id] = item.interested;
      return acc;
    }, {});

    // Update the 'interested' field of subquestions in the results object
    let newResults = results.map((result) => ({
      ...result,
      response: result.response.map((subquestion) => ({
        ...subquestion,
        interested: updatedSubquestionsMap.hasOwnProperty(subquestion.label)
          ? updatedSubquestionsMap[subquestion.label]
          : subquestion.interested,
      })),
    }));
	
	localStorage?.setItem("SA_SURVEY_STATE", JSON.stringify(newResults))
	setResults(newResults);
  };

  return (
    <Card className="h-md-100 mx-auto mb-2 mt-2  p-2 border-0 rounded-3">
      <Card.Header
        className="d-flex border-0 bg-transparent "
        style={{ justifyContent: "space-between" }}
      >
        <div className="mt-3 float-left clickable">
          <FontAwesomeIcon
            color={"#163c5d"}
            onClick={() => handleBackButton()}
            icon={faChevronLeft}
          />
        </div>
        <div className=" mt-3 float-right">
          <img width={50} src={logo}></img>
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column align-items-center justify-content-center">
        <ProgressBar completed={percent} bgcolor={"#23ceae"} />

        <Row className="mt-5">
          <Col>
            <h4>Skills that I'm interested in learning more about</h4>
            <p>
            Below, you'll find a list of all 35 skills and their definitions. Please mark the skills you want to learn more about, regardless of your current proficiency level. You can select as many or as few skills as you'd like.
            </p>
            <AccordionCheckBox
              interested={interested}
              setInterested={setInterested}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex bg-transparent border-0 text-right">
            <Button
              className="mx-auto btn-primary btn-lg float-right rounded-5 transition-button"
              onClick={handleClick}
            >
              Next <FontAwesomeIcon icon={faCirclePlay} />
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LearningFrame;
