import React, { useEffect, useState } from "react";
import { Accordion, Card, Form, ListGroup, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSurveyProvider } from "../../contexts/SurveyContext";

const AccordionCheckBox = ({ interested, setInterested }) => {
  const [questions, setQuestions] = useState([]);
  const { results, skillData } = useSurveyProvider();

  const getQuestions = () => {
    let q = results
      // .filter((r) => r.response.some((res) => res.checked === false))
      .map((answers) => {
        const skill = skillData.filter((s) => +s.id === +answers.id);
        const uncheckedSubQs = answers.response.filter(
          (res) => res.interested === false
        );
        return {
          question: skill && skill[0].question,
          definition: skill && skill[0]?.subquestion,
          questionId: answers.id,
          subquestions: uncheckedSubQs.map((res) => ({
            id: res.label,
            interested: res?.interested ?? false,
            question:
              skill[0]?.subquestions?.find((subQ) => subQ.id === res.label)
                ?.question ?? null,
          })),
        };
      });
    return q;
  };

  useEffect(() => {
    let q = getQuestions();
    if (q) {
      let int = q.map((q) => q.subquestions).flat(1);
      setQuestions(q);
      setInterested(int);
    }
  }, [results]);

  const handleCheck = (id) => {
    let update = interested.map((u) => {
      if (u.id === id) {
        return { ...u, interested: !u.interested };
      }
      return u;
    });
    setInterested(update);
  };

  const handleCheckAll = (id) => {
    let update = interested.map((u) => {
      const belongsToQuestionGroup = questions
        .find((q) => q.questionId === id)
        .subquestions.some((subQ) => subQ.id === u.id);
      if (belongsToQuestionGroup) {
        return { ...u, interested: !u.interested };
      }
      return u;
    });
    setInterested(update);
  };

  const areAllSubquestionsChecked = (questionId) => {
    const subquestions = questions.find(
      (q) => q.questionId === questionId
    ).subquestions;
    return subquestions.every(
      (sq) => interested.find((i) => i.id === sq.id)?.interested === true
    );
  };

  const questionsMarked = (questionId) => {
    const subquestions = questions.find(
      (q) => q.questionId === questionId
    ).subquestions
    return `(${subquestions.length})`

  }

  const onCheckboxClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {questions.length > 0 && (
        <div>
          <Accordion>
            {questions.map((questionGroup, i) => (
              <Accordion.Item
                eventKey={i}
                key={questionGroup.questionId}
                expanded
              >
                <Accordion.Header>
                  <Form.Check
                    type="checkbox"
                    id={`question-${questionGroup.questionId}`}
                    label={questionGroup.question}
                    onChange={() => handleCheckAll(questionGroup.questionId)}
                    checked={areAllSubquestionsChecked(
                      questionGroup.questionId
                    )}
                    onClick={onCheckboxClick}
                  />
                  
                </Accordion.Header>
                <Accordion.Body>
                  <span>
                    {questionGroup.definition}
                  </span>
                  {/* {questionGroup.subquestions.map((q) => {
                    console.log("q", q)
                    let question = q.question;
                    return (
                      <div key={q.id}>
                        <Form.Check
                          type="checkbox"
                          id={`subquestion-${q.id}`}
                          label={question}
                          onChange={() => handleCheck(q.id)}
                          checked={
                            interested.find((i) => i.id === q.id).interested
                          }
                        />
                      </div>
                    );
                  })} */}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      )}
    </>
  );
};

export default AccordionCheckBox;
