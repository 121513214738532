const ProgressBar = (props) => {
	const { bgcolor, completed } = props;
  
	const containerStyles = {
	  height: 20,
	  width: '75%',
	  backgroundColor: "#e0e0de",
	  borderRadius: 50,
	  margin: 5,

	}
  
	const fillerStyles = {
	  height: '100%',
	  width: `${completed}%`,
	  backgroundColor: bgcolor,
	  borderRadius: 'inherit',
	  textAlign: 'center',
	  alignItems: 'center',
	//   padding: 10
	}
  
	const labelStyles = {
	  padding: 5,
	  color: 'white',
	  fontSize: '10pt'
	}
  
	return (
	  <div style={containerStyles}>
		<div style={fillerStyles}>
		  <span style={labelStyles}></span>
		</div>
	  </div>
	);
  };
  
  export default ProgressBar;