export function groupBy(array, group) {
    var hash = Object.create(null),
        result = [];

    array.forEach(function (a) {
        if (!hash[a[group]]) {
            hash[a[group]] = [];
            result.push(hash[a[group]]);
        }
        hash[a[group]].push(a);
    });
    return result;
}

export function getFormattedDate(datestring) {
    let d = new Date(datestring) 
    let day = d.getDate(); 
    let month = d.getMonth() + 1 
    let year = d.getFullYear() 

    return month + '/' + day + '/' + year
}

export function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  export const splitArrayIntoColumns = (array, maxItemsPerColumn) => {
    const columnCount = Math.ceil(array.length / maxItemsPerColumn);
    const result = Array.from({ length: columnCount }, () => []);
  
    let columnIndex = 0;
    for (let i = 0; i < array.length; i++) {
      if (i % maxItemsPerColumn === 0 && i !== 0) {
        columnIndex++;
      }
      result[columnIndex].push(array[i]);
    }
  
    return result;
  };
  