import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SideBar } from "./components/Sidebar";
import useAuthProvider from "./contexts/AuthContext";
import LocalStorageModal from "./components/LocalStorageModal";
const Wrapper = ({ children }) => {
  const { session } = useAuthProvider();

  const handleKeyDown = (event) => {
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault();
    }
  }

  
  

  return (
    <Container onKeyDown={handleKeyDown} fluid className="overflow-hidden bg-light">
      <Row className="vh-100 overflow-auto">
        <SideBar />
        <Col className="h-100 d-flex flex-column h-sm-100">
          <Row className="h-100">
           {session?.user?.id && <Col className="vh-100 pt-4">{children}</Col> }
          </Row>
        </Col>
      </Row>
      
    </Container>
  );
};

export default Wrapper;
