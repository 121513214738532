import React, { useContext, useState, createContext, useEffect, useRef } from "react";
import surveyconfig from "../components/survey/config/survey.json";
import { supabase } from "../lib/supabaseClient";
import useAuthProvider from "./AuthContext";
import { shuffle } from "../lib/helpers";
export const SurveyContext = createContext();

export function SurveyContextProvider({ children }) {
  const { session } = useAuthProvider();
  // const [surveyState, setSurveyState] = useState(false);
  //results contain the results of the survey.
  const [results, setResults] = useState(
    JSON.parse(localStorage?.getItem("SA_SURVEY_STATE")) ?? []
  );
  // answer contains the answer of the current question
  const [answer, setAnswer] = useState(null);
  //if the question is required, and the question is submmitted without being fully answered, set an error
  const [requiredError, setRequiredError] = useState(false);
  //whether or not the survey is completed
  const [surveyCompleted, setsurveyCompleted] = useState(false);
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [survey, setSurvey] = useState([]);
  //check on this
  const [userID, setUserID] = useState(null);
  const [finalIndex, setFinalIndex] = useState(null);
  const [skillData, setSkillData] = useState([]);
  const [response_id, setResponseId] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false)

  const questionRef = useRef(null) 
  const executeScroll = () => {
    setTimeout(() => questionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 500); 
  }

  const skill_groups = {
    1: "Professional",
    2: "Technical",
  };

  const answer_key = {
    1: "Foundational",
    2: "Approaching",
    3: "Proficient",
  };

  const getSurveyStatus = async () => {
    const { user } = session;
    const { data, error } = await supabase
      .from("responses")
      .select(`is_completed, response_id`)
      .eq("id", user.id)
      .single();

    setsurveyCompleted(data.is_completed);
    setResponseId(data.response_id);
  };

  useEffect(() => {
    if (session?.user) {
      getSurveyStatus();
    }
  }, [session, surveySubmitted]);

  const getData = async () => {
    let { data, error } = await supabase
      .from("skill_groups")
      .select(
        `
    id,
    skill_title,
    skill_description,
    skill_statements (
      id,
      skill_statement
    )
  `
      )
      .order('id', {ascending: true})
      // .range(0, 5);

    if (error) {
    } else {
      let skillsdata = data.map((d) => {
        return {
          id: d.id,
          section: (d.id += 1),
          question: d.skill_title,
          subquestion: d.skill_description,
          questiontype: "toggle",
          options: [],
          answer: null,
          subquestions: shuffle(d.skill_statements).map((s) => {
            return {
              id: s.id,
              question: s.skill_statement,
              answer: null,
              additional: null,
            };
          }),
        };
      });
      setFinalIndex(Math.max(...skillsdata.map((d) => d.id)));
      setSkillData(skillsdata);
    }
  };

  //load survey questions from config file (will be database)
  useEffect(() => {
    let [config] = surveyconfig;
    setSurvey(config);
    // setFinalIndex(34);
    getData();
    
  }, []);

  //when results are updated or removed save it to local storage
  useEffect(() => {
    localStorage?.setItem("SA_SURVEY_STATE", JSON.stringify(results));
  }, [results]);

  // index is how we tell what page we are on. Starts at 0.
  const [index, setIndex] = useState(0);

  //when you click next on the carousel it sets the index
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  //check if the results already contains the index that the page is on.
  const checkResults = (obj) => obj.id === index;

  //update results if you've answered a question otherwise just 'pass' through it
  const updateResults = () => {
    //click back but don't change answer; nothing should change
    if (answer === null && results.some(checkResults)) {
      
      return;
    }
    //updating an already existing answer to a different answer
    //TODO: if you go back and don't change anything, the index's answer is overwritten with the question you went back from 
    if (results.some(checkResults)) {
      let res = results;
      let idx = res.findIndex((ele) => ele.id === index);
      res[idx].response = answer;
      setResults(res);
      //because this is a deep change it isn't recognized by useffect to update localstorage - update manually
      localStorage.setItem("SA_SURVEY_STATE", JSON.stringify(res));
    } else {
      //answer doesn't exist yet for the question
      setResults([...results, { id: index, response: answer }]);
    }
  };

  const getExistingAnswer = (id) => {
    if (answer === null) {
      let idx = results.findIndex((r) => r.id === id);
      return (results[idx] && results[idx].response) ?? null;
    } else {
      return answer;
    }
  };

  const allFalse = (ele) =>
    ele.checked === false &&
    ele.interested === false &&
    ele.applicable === false;

  const isNull = (ele) => ele.checked === null

  const handleSubmitAnswer = (props) => {
    setDisabledButton(true)
    // if the results don't have an answer for that index, set it as not answered. This needs to be updated because
    // the question could be only half answered
    if (
      results.findIndex((r) => r.id === index) === -1 &&
      (answer === null || answer.some(isNull))
    ) {
      setRequiredError(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      executeScroll()
      setTimeout(() => setDisabledButton(false), 500)
    } else {
      updateResults();
      setIndex(index + 1);
      setAnswer(null);
      setRequiredError(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      executeScroll()
      setTimeout(() => setDisabledButton(false), 500)
      
    }
    // } else if (index === finalIndex) {
    //   // setsurveyCompleted(true);
    //   setIndex(index + 1);
    // }
  };

  const handleBackButton = () => {
    if (index > 0) {
      setIndex(index - 1);
      setAnswer(null);
      setRequiredError(null);
    }
  };

  const handleNextButton = () => {
    setIndex(index + 1);
    window.scrollTo(0, 0);
  };

  // if retaking the survey, set everything back to initial state
  const retakeSurvey = () => {
    localStorage.setItem("SA_SURVEY_STATE", JSON.stringify([]));
    setAnswer(null);
    setResults([]);
    setIndex(0);
  };

  const submitSurvey = async () => {
    if (results.length > 1) { 
      setSurveySubmitted(true);
      const { user } = session;
  
      const { data: response_tbl, error: response_id_error } = await supabase
        .from("responses")
        .select(`response_id`)
        .eq("id", user.id)
        .single();
  
      if (response_id_error) {
        console.log(response_id_error);
        return;
      }
  
      let res = results.map((res) => {
        return res.response.map((r) => {
          return {
            skill_response_id: response_tbl.response_id,
            skill_statement_id: r.label,
            skill_response: r.checked,
            skill_interest: r.interested,
            skill_applicable: r.applicable,
          };
        });
      });
  
      const { error } = await supabase
        .from("skill_response_answers")
        .insert(res.flat(1));
  
      if (error) {
        console.log(error);
      }
  
      getSurveyStatus();
    } else {
      alert("Something isn't right. It appears you have not answered the questions.")
    }
    // setsurveyCompleted(true)
    
  };
  //toggle

  const percent = Math.round((index / (finalIndex + 2)) * 100);
  return (
    <SurveyContext.Provider
      value={{
        // surveyState,
        // setSurveyState,
        index,
        setIndex,
        results,
        setResults,
        handleSelect,
        handleSubmitAnswer,
        answer,
        setAnswer,
        requiredError,
        handleBackButton,
        handleNextButton,
        surveyCompleted,
        setsurveyCompleted,
        survey,
        setSurvey,
        checkResults,
        getExistingAnswer,
        retakeSurvey,
        userID,
        setUserID,
        finalIndex,
        skillData,
        submitSurvey,
        surveySubmitted,
        response_id,
        skill_groups,
        answer_key,
        percent, 
        setRequiredError,
        questionRef, 
        executeScroll,
        disabledButton
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
}

export function useSurveyProvider() {
  const context = useContext(SurveyContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
