import {
  faCheckCircle,
  faEdit,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {
  Card,
  Alert,
  Button,
  ButtonGroup,
  Row,
  Col,
  ProgressBar,
  Placeholder,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/profile.css";
import { Link } from "react-router-dom";
import logo from "../static/img/GM.png";
import { useSurveyProvider } from "../contexts/SurveyContext";
import { ConfigureModal } from "./profile/Modal";
import useProfileProvider from "../contexts/ProfileContext";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { EmployeeDetails } from "./profile/EmployeeDetail";
import { useNavigate } from "react-router-dom";

const Configure = () => {
  const { id, full_name, setUpError } = useProfileProvider();

  const navigate = useNavigate();

  return (
    <Card className="h-100 mt-2 shadow-sm p-3 border-0 rounded-3">
      {console.log("setuperror", setUpError)}
      <Card.Header
        className="teal p-3 text-white border-0 rounded-0 d-flex text-thin"
        style={{ justifyContent: "space-between" }}
      >
        <div className="text-left align-content-center justify-content-center">
          <h2 className="text-thin mx-auto text-left justify-content-center">
            Thanks For Being Here {full_name ?? ""}!
          </h2>
        </div>

        <div className="text-right align-content-end">
          <img className="" width={50} src={logo} alt="GM logo"></img>
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Title>
          <Row>
            <div className="text-center">
              <h4>Welcome to the SA3 Profile Builder</h4>
              <p>Your personalized skills assessment and employee profile.</p>
            </div>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <Card className="h-100 card-1 rounded-0">
                <Card.Header className="mt-1 text-center rounded-0 bg-transparent text-black border-0">
                  <div
                    className="p-2"
                    style={{ borderBottom: "3px solid #23ceae" }}
                  >
                    Configure your account.
                  </div>
                </Card.Header>
                <Card.Body className="p-5" style={{ textAlign: "justify" }}>
                  <p className="text-thin text-black">
                    To configure your profile, select the edit account button and enter your GMIN.
                    Your account will auto populate with the job family, level
                    and position we have on file. If this information is
                    correct, please move on to the assessment. If this
                    information is incorrect, you may update the information by
                    selecting the edit account button.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-12 col-md-6">
              <Card className="mt-2 mt-lg-0 h-100 card-2 rounded-0">
                <Card.Header className="mt-1 text-center rounded-0 bg-transparent text-black border-0">
                  <div
                    className="span-border p-2"
                    style={{ borderBottom: "3px solid #23ceae" }}
                  >
                    Take the SA3 assessment.
                  </div>
                </Card.Header>
                <Card.Body className="p-5" style={{ textAlign: "justify" }}>
                  <p className="text-thin text-black">
                    Once your account is complete, you are ready to take the
                    assessment. The assessment feeds directly into your overall
                    skills profile – a comprehensive view of current skill
                    proficiencies and future areas of opportunity – which you
                    will be able to view and review once your assessment is
                    submitted.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row> 
            <Col> 
            {setUpError && <Alert className = "m-5 p-3" variant="danger">
              <h5>This Employee ID either does not exist or is already in use!</h5>
              <p className = "responsive-text" style={{fontWeight: 200}}>Please ensure you are entering the correct ID. If you think this is an error, or need to reset your account, please contact your manager.</p> 
              </Alert>}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col style={{ textAlign: "right" }}>
            
              <ConfigureModal
                // setIdExists={setIdExists}
                icon={<FontAwesomeIcon icon={faGear} />}
                text={id ? "Edit Account" : "Configure Account"}
                styles={"transition-button mr-auto  border-0 rounded-5 mt-5"}
              />
            </Col>
          </Row>
        </Card.Title>
        <Card.Text>
          <EmployeeDetails />
        </Card.Text>
        {id && (
          <Row>
            <Col>
              {/* <h5 className="m-2 p-1">All Set?</h5> */}
              {/* <Button className="mt-3 transition-button rounded-5"> */}
              <Link
                to="/assessment"
                className="btn btn-lg transition-button rounded-5 text-white"
              >
                Take the Assessment
              </Link>

              {/* </Button> */}
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default Configure;
