import React from "react";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import { Form } from "react-bootstrap";

export const ShortAnswerQuestion = ({ id }) => {
  const { 
	setAnswer, 
	requiredError, 
	handleSubmitAnswer, 
	getExistingAnswer } = useSurveyProvider();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmitAnswer();
    }
  };

  return (
    <Form className="mt-2 mb-2 w-75">
      <Form.Control
	  	key={id}
        onChange={(e) => setAnswer(e.target.value)}
        defaultValue={getExistingAnswer(id - 1)}
        className="rounded-0 no-outline"
        placeholder="Type your answer here..."
        required
        autoFocus
        isInvalid={requiredError}
        onKeyDown={(e) => handleKeyPress(e)}
      />
      <Form.Control.Feedback type="invalid">
        This question is required.
      </Form.Control.Feedback>
    </Form>
  );
};
