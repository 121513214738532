import React from "react";
import { Row, Col } from "react-bootstrap";

const Equalizer = ({ trueCount }) => {
  const boxCount = 6;
  const falseCount = boxCount - trueCount;
  const boxWidth = 10;
  const boxHeight = 30;

  const colorScheme = ["#23ceae", "#23ceae", "#1f9e6c", "#1f9e6c", "#163c5d", "#163c5d"];
  const blendedColorScheme = colorScheme.map((color, index, arr) => {
    if (index === 0 || index === arr.length - 1 || index % 2 === 0) {
      return color;
    } else {
      const prevColor = arr[index - 1];
      const nextColor = arr[index + 1];
      return blendColors(prevColor, nextColor);
    }
  });

  function blendColors(color1, color2) {
    const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
    if (!hexRegex.test(color1) || !hexRegex.test(color2)) {
      throw new Error("Invalid color format");
    }

    const hexToRgb = (hex) => {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return { r, g, b };
    };

    const rgbToHex = (rgb) => {
      const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };
      return `#${componentToHex(rgb.r)}${componentToHex(rgb.g)}${componentToHex(rgb.b)}`;
    };

    const c1 = hexToRgb(color1);
    const c2 = hexToRgb(color2);

    const blend = (c1, c2, ratio) => {
      const r = Math.round(c1.r + (c2.r - c1.r) * ratio);
      const g = Math.round(c1.g + (c2.g - c1.g) * ratio);
      const b = Math.round(c1.b + (c2.b - c1.b) * ratio);
      return { r, g, b };
    };

    const ratio = 0.5;
    const blended = blend(c1, c2, ratio);
    return rgbToHex(blended);
  }

  const falseBoxStyle = {
    width: `${boxWidth}px`,
    height: `${boxHeight}px`,
    backgroundColor: "rgb(239 236 236)",
    margin: "0.15rem",
    display: "inline-block"
  };
  const trueBoxStyle = (index) => ({
    width: `${boxWidth}px`,
    height: `${boxHeight}px`,
    backgroundColor: trueCount === 0 ? "#ccc" : blendedColorScheme[index],
    margin: "0.15rem",
    display: "inline-block"
  });

  return (
    <div>
      {[...Array(boxCount)].map((_, i) => (
        <div key={i} style={i < trueCount ? trueBoxStyle(i) : falseBoxStyle}></div>
      ))}
    </div>
  );
};

export default Equalizer;





