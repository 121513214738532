import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Survey from "../components/Survey";
import { useSurveyProvider } from "../contexts/SurveyContext";



const SurveyPage = () => {
  const { questionRef } = useSurveyProvider();
  return (
    <Container fluid className="h-100 mb-5">
      <Row ref = {questionRef} className = "h-90 mb-5">
        <Col className="h-90 mb-5">
          <Survey />
        </Col>
      </Row>
    </Container>
  );
};

export default SurveyPage;
