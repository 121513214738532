import { faBan, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Card, Row, Col, Tab, Tabs, Placeholder } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../static/css/profile.css";
import { groupBy } from "../../lib/helpers";
import useProfileProvider from "../../contexts/ProfileContext";
import logo from "../../static/img/GM.png";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import { ScoringKey } from "./Key";
import { EmployeeDetails } from "./EmployeeDetail";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import Equalizer from "./Equalizer";
import { getFormattedDate } from "../../lib/helpers";
const TeamProfile = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [key, setKey] = useState("Skills");
  const { teamMember } = useProfileProvider();

  const { skill_groups, skillData } = useSurveyProvider();

  const getCircleClassName = (result, level, baseClass) => {
    let className = "circle";

    if (level <= result.skill_level_recoded) {
      className += " active";
    }

    if (baseClass) {
      className += ` ${baseClass}`;
    }

    return className;
  };

  const getCircleType = (result) => {
    const level = parseInt(result.skill_level_recoded); // convert to number
    console.log("level", level);
    console.log("level ", level === 1);
    if (level === 0) {
      return "circle-hatch";
    } else if (level === 1) {
      return "circle";
    } else if (level === 2) {
      return "circle-half";
    } else if (level === 3) {
      return "circle-filled";
    } else {
      console.log("error!");
    }
  };

  const chunkedSkills = [];
  const groupSize = 12;
  for (let i = 0; i < teamMember?.results.length; i += groupSize) {
    chunkedSkills.push(teamMember?.results.slice(i, i + groupSize));
  }
  return (
    <Card className="w-100 vh-100 mb-2 mt-2 shadow-sm p-2 border-0 rounded-3 overflow-auto">
      <Card.Header
        className="teal p-3 text-white border-0 rounded-0 d-flex text-thin"
        style={{ justifyContent: "space-between" }}
      >
        <div className="text-left align-content-center justify-content-center">
          <h2 className="text-thin mx-auto text-left justify-content-center">
            {teamMember?.name ?? ""}'s Profile
          </h2>
        </div>

        <div className="text-right align-content-end">
          <img className="" width={50} src={logo} alt="GM logo"></img>
        </div>
      </Card.Header>
      <Card.Body >
      <Row className="justify-content-md-center">
      <Col className="col-12 col-md-4 h-100 ">
        <Card className="teal-light border-0">
          <Card.Body>
            <p>
              <strong>Team Member: </strong>
            </p>
            <p> {teamMember?.name ?? <Placeholder xs={6} />} </p>
          </Card.Body>
        </Card>
      </Col>
      <Col className="mt-1 mt-md-0 col-12 col-md-4 h-100 ">
        <Card className="teal-light border-0">
          <Card.Body>
            <p>
              <strong>Job Title:</strong>
            </p>
            <p> {teamMember?.title ?? <Placeholder xs={6} />} </p>
          </Card.Body>
        </Card>
      </Col>
      <Col className="col-12 col-md-4 h-100 ">
        <Card className="mt-1 mt-md-0 teal-light border-0">
          <Card.Body>
            <p>
              <strong>Completed:</strong>
            </p>
            <p> {getFormattedDate(teamMember?.compeleted)} </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
        {/* <ScoringKey /> */}
        {teamMember?.results.length > 0 && (
          
          <Card.Text>
           
                <div
                  className="p-2 mt-5 mb-2"
                  style={{ height: "600px" }}
                >
                  <h5 className="mx-1 w-75 w-md-25 mb-3 p-2" style={{borderBottom: '1px solid #23ceae'}}>{teamMember.name}'s Skills Profile</h5>
                  {/* <p className="mx-1">
                    Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas. Proin pharetra nonummy
                    pede. Mauris et orci.
                  </p> */}
                  <div className="">
                    <Row>
                      {chunkedSkills.map((chunk) => {
                        return (
                          <Col className="col-12 col-md-4">
                            {chunk.map((result) => {
                              return (
                                <Row
                                  className="p-1"
                                  style={
                                    {
                                      // borderBottom: "1px solid lightgray",
                                    }
                                  }
                                >
                                  <Col className="mx-auto col-12 col-md-6 ">
                                    <OverlayTrigger
                                      placement="top" // You can change this to "right", "bottom", or "left" if needed
                                      overlay={
                                        <Popover
                                          id="popover-basic"
                                          className="rounded-0 w-150"
                                        >
                                          <Popover.Header
                                            as="h3"
                                            className="teal rounded-0"
                                          >
                                            {result.skill_title}
                                          </Popover.Header>
                                          <Popover.Body>
                                            {
                                              skillData.filter(
                                                (s) =>
                                                  s.question ==
                                                  result.skill_title
                                              )[0]?.subquestion
                                            }
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <span className="d-inline">
                                        <FontAwesomeIcon
                                          icon={faInfoCircle}
                                          color={"#ffb236"}
                                        />{" "}
                                        {result.skill_title}
                                      </span>
                                    </OverlayTrigger>
                                  </Col>
                                  <Col className="p-0 mx-auto col-8 col-md-6 justify-content-end text-right">
                                    {/* <span
                                      className={`circle  ${
                                        result.skill_level_recoded === 3
                                          ? "circle-filled active"
                                          : result.skill_level_recoded === 2
                                          ? "circle-half active"
                                          : result.skill_level_recoded === 0
                                          ? "circle-hatch"
                                          : ""
                                      }`}
                                    ></span> */}
                                    <Equalizer trueCount={result.skill_answer_count} />
                                  </Col>
                                  {/* <Col className="flex p-0 mx-2 col-2 text-center">
                                      <span
                                        className={
                                          result.skill_interest > 0
                                            ? "circle icon gold"
                                            : "circle icon"
                                        }
                                        style={{ border: "none" }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCheckCircle}
                                          color="white"
                                        />
                                      </span>
                                    </Col> */}
                                </Row>
                              );
                            })}
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
             
              {/* <Tab eventKey={"interest"} title={`Skills ${name} is Interested In`}>
                <Row>
                  {groupBy(skills_interest, "skill_group_id").map(
                    (skill_group) => {
                      return (
                        <Col className="col-auto p-3 m-2">
                          <Card className="teal-light border-0">
                            <Card.Body>
                              <Card.Text
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setSelectedId(skill_group[0].skill_group_id)
                                }
                              >
                                <p className="d-inline m-2 ">
                                  {
                                    skills_summary.find(
                                      (s) =>
                                        s.id === skill_group[0].skill_group_id
                                    )?.skill_title
                                  }
                                </p>
                                <span
                                  className="circle text-white"
                                  style={{
                                    background: "#ffb236",
                                    transform: "scale(0.7)",
                                  }}
                                >
                                  {skill_group.length}
                                </span>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    }
                  )}
                </Row>
                <Row>
                  <Col className="col-12 col-md-2 m-3">
                    <h5 style={{ borderBottom: "3px solid #23ceae" }}>
                      {
                        skills_summary.find((s) => s.id === selectedId)
                          ?.skill_title
                      }
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ul className="no-bullet">
                      {selectedId ? skills_interest
                        .filter((s) => s.skill_group_id === selectedId)
                        .map((d) => {
                          return (
                            <li>
                              {" "}
                              <em>{d.skill}</em>
                            </li>
                          );
                        }) : "Select a skill above to view the Sub-Skills that you marked as being interested in."}
                    </ul>
                  </Col>
                </Row>
              </Tab> */}
    
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default TeamProfile;
