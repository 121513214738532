import {
  faCheck,
  faHouse,
  faChevronLeft,
  faRightFromBracket,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Button, Alert } from "react-bootstrap";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import Question from "./QuestionTypeSelector";
import "../../static/css/survey.css";
import logo from "../../static/img/gm_blue.png";
import ProgressBar from "./ProgressBar";
import Select from "react-select";
import AccordionCheckBox from "./AccordionChecks";
const SubmitFrame = ({ props }) => {
  const {
    handleBackButton,
    skillData,
    index,
    setIndex,
	// setsurveyCompleted,
	submitSurvey,
	results, 
	setResults
  } = useSurveyProvider();

  const opts = skillData.map((skill) => {
    return {
      label: skill.question,
      value: skill.id,
    };
  });

  return (
    <Card className="vh-md-100 mx-auto mb-2 mt-2  p-2 border-0 rounded-3">
      <Card.Header
        className="d-flex border-0 bg-transparent "
        style={{ justifyContent: "space-between" }}
      >
        <div className="mt-3 float-left clickable">
          <FontAwesomeIcon
            color={"#163c5d"}
            onClick={() => handleBackButton()}
            icon={faChevronLeft}
          />
        </div>

        <div className=" mt-3 float-right">
          <img width={50} src={logo}></img>
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column align-items-center justify-content-center">
        <ProgressBar completed={100} bgcolor={"#23ceae"} />
        <h1 className="mt-5">Assessment Complete!</h1>
        <Card.Title className="align-items-center"></Card.Title>
        <Card.Subtitle className="m-3 p-2">
          <p className="lead">
		  {/* You have completed all sections of the assessment. If you are
            comfortable with your anwers, you can submit your answers with the
            button below. If you'd like to adjust any of your answers, use the
            dropdown to navigate to the skill you'd like to update, or navigate
            using the arrow on the top left of this page.{" "} */}

            You have completed all sections of the assessment and you may now submit your responses. 
            Once you have submitted your responses your profile will be complete. 
          </p>
        </Card.Subtitle>
		{/* <Card.Text>
        <Select
          key={index}
          options={opts}
          onChange={(e) => setIndex(e.value)}
          placeholder={"Navigate to Skill..."}
          defaultValue={null}
          //   isOptionDisabled={(o) => o.disabled}
          // isInvalid={requiredError}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: "max-content",
              minWidth: "75%",
              margin: "auto",
              marginTop: "2rem",
              borderBottom: "3px solid #23ceae !important",
              borderTop: "0px",
              borderRight: "0px",
              borderLeft: "0px",
              fontSize: "18pt !important",
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            border: "none !important",
            borderColor: "transparent",
            borderBottom: "3px solid #23ceae !important",
            fontSize: "18px",
            colors: {
              ...theme.colors,
              primary50: "#819dad",
              primary25: "#D0D8DE",
              primary: "#23ceae",
            },
          })}
        />
		</Card.Text> */}
		<Card.Text>
        <Alert className="mt-5" variant="warning">
          {" "}
          <p>
            Once your answers are submitted you will not be able to adjust or
            retake the survey. Your skills profile will be developed and made
            available once you submit your answers.
          </p>
          <p className="text-center">
            <strong>
              {" "}
              Be sure you are ready to submit prior to proceeding!{" "}
            </strong>
          </p>
        </Alert>
		</Card.Text>
        <Button
          className="btn-lg mt-5 rounded-5 transition-button"
          onClick={() => submitSurvey()}
          onKeyDown={(e) => e.key === "Enter" && submitSurvey()}
        >
          <span>SUBMIT</span> <FontAwesomeIcon icon={faRightLong} />{" "}
        </Button>
		
      </Card.Body>
    </Card>
  );
};

export default SubmitFrame;
