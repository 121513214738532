import React from "react";
import { ShortAnswerQuestion } from "./ShortAnswerQuestion";
import { DropDownQuestion } from "./DropDownQuestion";
import { RangeQuestion } from "./SliderQuestion";
import { PreSkillQuestion } from "./SkillPreQuestion";
import { StepProgression } from "./Section";
import { MCQuestion } from "./MultipleChoiceQuestion";
import { ToggleQuestion } from "./ToggleQuestion";
import { SCQuestion } from "./SingleChoice";

export const QuestionTypeSelector = {
  shortanswer: ShortAnswerQuestion,
  dropdown: DropDownQuestion,
  rangeslider: RangeQuestion,
  skillquestion: PreSkillQuestion,
  section: StepProgression,
  multiple: MCQuestion,
  toggle: ToggleQuestion,
  single: SCQuestion
};

export default function Question(props) {
  const QuestionComponent = QuestionTypeSelector[props.questiontype];
  return (
    <QuestionComponent
      options={props.options}
      answer={props.answer}
      id={props.id}
    />
  );
}
