import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Row, Col, Table, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useProfileProvider from "../contexts/ProfileContext";
import { getFormattedDate } from "../lib/helpers";
import logo from "../static/img/GM.png";


const ManagerView = () => {
  const navigate = useNavigate();
  const { directReportData, setTeamMember, directReportCount, team } =
    useProfileProvider();

  const handleViewTeamMember = (d) => {
    setTeamMember(d);
    navigate("/team/profile");
  };

  const map = new Map();
  team?.forEach((item) => map.set(item.emplid, item));
  directReportData?.forEach((item) =>
    map.set(item.emplid, { ...map.get(item.emplid), ...item })
  );

  const mergedArr = Array.from(map.values());



  return (
    <Card className="w-100 vh-100 mb-2 mt-2 shadow-sm p-2 border-0 rounded-3 overflow-auto ">
      <Card.Header
        className="teal p-3 text-white border-0 rounded-0 d-flex text-thin"
        style={{ justifyContent: "space-between" }}
      >
        <div className="text-left align-content-center justify-content-center">
          <h2 className="text-thin mx-auto text-left justify-content-center">
            View Your Team's Results
          </h2>
        </div>

        <div className="text-right align-content-end">
          <img className="" width={50} src={logo} alt="GM logo"></img>
        </div>
      </Card.Header>
      <Card.Body className="bg-transparent">
        <Card.Text>
          <Row className="justify-content-md-center">
            <Col className="col-12 col-md-4 h-100 ">
              <Card className="teal-light border-0">
                <Card.Body>
                  <p>
                    <strong>Team</strong>
                  </p>
                  <p> Data & Analytics Team </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mt-1 mt-md-0 col-12 col-md-4 h-100 ">
              <Card className="teal-light border-0">
                <Card.Body>
                  <p>
                    <strong>Total Direct Reports</strong>
                  </p>
                  <p> {directReportCount} </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-12 col-md-4 h-100 ">
              <Card className="mt-1 mt-md-0 teal-light border-0">
                <Card.Body>
                  <p>
                    <strong>Profiles Completed</strong>
                  </p>
                  <p>{directReportData.length} </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Text>
        <Table className="mx-auto p-3 m-3" border hover>
          <thead>
            <tr>
              <th> Name </th>
              <th> Job Title </th>
              <th> Date Completed </th>
              <th> View </th>
            </tr>
          </thead>
          <tbody>
            {directReportData?.length > 0 &&
              mergedArr?.map((d) => {
                return (
                  <tr>
                    <td>{d.name}</td>
                    <td>{d.title}</td>
                    <td>
                      {d.compeleted
                        ? getFormattedDate(d.compeleted)
                        : "Not Completed"}{" "}
                    </td>
                    <td>
                      {d.compeleted && (
                        <Button
                          className="bg-transparent border-0"
                          onClick={() => handleViewTeamMember(d)}
                        >
                          <FontAwesomeIcon icon={faEye} color={"#23ceae"} />
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            {directReportData?.length == 0 &&
              team &&
              team?.map((d) => {
                return (
                  <tr>
                  <td>{d.name}</td>
                  <td>{d.title}</td>
                  <td>
                    <span>Not Completed</span>
                  </td>
                  <td>
                   
                  </td>
                </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ManagerView;
