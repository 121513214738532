import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useSurveyProvider } from "../../contexts/SurveyContext";

export const SCQuestion = () => {
  const { setAnswer } = useSurveyProvider();
  const [values, setValues] = useState([]);

  const [selection, setSelection] = useState(null); 


  const selectItem = (val) => {
    if (values.includes(val)) {
      let v = values.filter(v => v !== val);
      setValues(v);
    } else {
      setValues([...values, val]);
    }
  };

  const prompts = [
    {
      id: "VIS",
	  level: 'A',
      description:
        "I often create interactive, animated and customized visualizations that can be considered standlone tools or embeded into an application. I often use Javascript libaries, such as D3.js, Plotly.js and other libararies to create a customized visualization that is catered to the data and audience. ",
    },
    { id: "VIS", level: 'B', description: "I am able to create basic charts and graphs using tools like Excel and google sheets. This may include bar graphs, line charts and pie charts. " },
    { id: "VIS", level: 'I', description: "I use tools such as tableau and power bi to create visualizations such as scatterplots, heatmaps and geographic maps. Often I create multiple visualiztions and present this as a dashboard." },
  ];

  return (
    <>
      {prompts.map((t) => {
        return (
          <Row className="mt-3 w-100 ">
            <Col className="">
              <Card
                onClick={() => {
					setSelection(t.level)
                }}
                className={
                  selection === t.level
                    ? "mx-auto selected-card h-100 w-100 w-md-50 p-2 m-2"
                    : "mx-auto selectable-card h-100 w-100 p-2 m-2"
                }
              >
                <Card.Text className="text-start">
                  <p className="responsive-font d-inline" >
                    {t.description}
                  </p>
                </Card.Text>
              </Card>
            </Col>
          </Row>
        );
      })}
    </>
  );
};