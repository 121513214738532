import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ManagerView from "../components/Manager";

const ManagerPage = () => {
  return (
    <Container fluid className="vh-100 mb-5">
      <Row>
        <Col className="h-100 w-100 col-12">
          <ManagerView />
        </Col>
      </Row>
    </Container>
  );
};

export default ManagerPage;