import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import ToggleSwitch from "./ToggleSwitch";

export const PreSkillQuestion = () => {
  const { setAnswer } = useSurveyProvider();
  const [value, setValue] = useState(null);

  const prompts = [
    {
      label: "Not Relevant",
      description: "This skill is not relevant to your role",
    },
    {
      label: "Interested in Learning",
      description:
        "You don't possess this skill, but you're interested in learning",
    },
    {
      label: "I Have this Skill",
      description: "I possess this skill and can rate my proficiency",
    },
  ];

  return (
    <>
      <Row className="w-100">
        {prompts.map((t) => {
          return (
            <Col className="col-xs-12 mt-1 ">
              <Card
                onClick={() => {
                  setValue(t.label);
                  setAnswer(t.label);
                }}
                className={
                  value === t.label
                    ? "selected-card h-100 w-100 p-2 m-2"
                    : "selectable-card h-100 w-100 p-2 m-2"
                }
              >
                <Card.Body className="text-center">
                  <h5>{t.label}</h5>
                  <p>{t.description}</p>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
