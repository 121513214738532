import { faBan, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { Card, Row, Col, Tab, Tabs, Placeholder } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../static/css/profile.css";
import { groupBy } from "../../lib/helpers";
import useProfileProvider from "../../contexts/ProfileContext";
import logo from "../../static/img/GM.png";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import { ScoringKey } from "./Key";
import { EmployeeDetails } from "./EmployeeDetail";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import Equalizer from "./Equalizer";

const SkillProfileSection = () => {
  const [selectedId, setSelectedId] = useState(null);

  const [key, setKey] = useState("Skills");
  const { full_name, skills_summary, skills_interest } = useProfileProvider();

  useEffect(() => {
    setSelectedId(skills_interest[0]?.skill_group_id);
  }, []);

  const { skillData } = useSurveyProvider();

  const chunkedSkills = [];
  const groupSize = 12;
  for (let i = 0; i < skills_summary.length; i += groupSize) {
    chunkedSkills.push(skills_summary.slice(i, i + groupSize));
  }

  return (
    <Card
      className="w-100 h-100 mb-2 mt-2 shadow-sm p-2 border-0 rounded-3 "
      style={{ minHeight: "600px" }}
    >
      <Card.Header
        className="teal p-3 text-white border-0 rounded-0 d-flex text-thin"
        style={{ justifyContent: "space-between" }}
      >
        <div className="text-left align-content-center ">
          <h2 className="text-thin mx-auto text-left ">
            {full_name ?? ""}'s Profile
          </h2>
        </div>

        <div className="text-right align-content-end">
          <img className="" width={50} src={logo} alt="GM logo"></img>
        </div>
      </Card.Header>
      <Card.Body className="bg-transparent">
        <Card.Text>
          <EmployeeDetails />
        </Card.Text>
        <Card.Text>
          <p className="p-5 border bg-light">
            The equalizer bar chart provides a visual representation of your
            skill profile.  The number of shaded boxes within each bar
            corresponds to the number of statements that you answered{" "}
            <span
              style={{
                borderRadius: "50px 0px 0px 50px",
                backgroundColor: "#163c5d",
                color: "white",
                padding: "5px",
              }}
            >
              YES
            </span>{" "}
            to, with a higher number of shaded boxes indicating greater
            proficiency in a specific skill.
          </p>
        </Card.Text>
        {/* <ScoringKey /> */}
        {skills_summary.length > 0 && (
          <Card.Text>
            <Tabs
              className=""
              id="results"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey={"Skills"} title="Skills Assessment Results">
                <div
                  className="p-2 mt-5 mb-2 "
                  // style={{ height: "500px" }}
                >
                  <h5 className="mx-1">Skills</h5>
                  <div>
                    <Row>
                      {chunkedSkills.map((chunk) => {
                        return (
                          <Col className="col-12 col-md-4">
                            {chunk.map((result) => {
                              return (
                                <Row
                                  className="p-1"
                                  style={
                                    {
                                      // borderBottom: "1px solid lightgray",
                                    }
                                  }
                                >
                                  <Col className=" col-12 col-md-6 ">
                                    <OverlayTrigger
                                      placement="top-end" // You can change this to "right", "bottom", or "left" if needed
                                      width={"200px"}
                                      overlay={
                                        <Popover
                                          id="popover-basic"
                                          className="rounded-0 w-150"
                                        >
                                          <Popover.Header
                                            as="h3"
                                            className="teal rounded-0"
                                          >
                                            {result.skill_title}
                                          </Popover.Header>
                                          <Popover.Body>
                                            {
                                              skillData.filter(
                                                (s) =>
                                                  s.question ==
                                                  result.skill_title
                                              )[0]?.subquestion
                                            }
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <span className="d-inline">
                                        <FontAwesomeIcon
                                          icon={faInfoCircle}
                                          color={"#ffb236"}
                                        />
                                      </span>
                                    </OverlayTrigger>
                                    <span> {result.skill_title}</span>
                                  </Col>
                                  <Col className="p-0 col-8 col-md-6 ">
                                    <Equalizer
                                      trueCount={result.skill_answer_count}
                                    />
                                  </Col>
                                </Row>
                              );
                            })}
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={"interest"} title="Skills I'm Interested In">
                <Row>
                  {groupBy(skills_interest, "skill_group_id").map(
                    (skill_group) => {
                      return (
                        <Col className="col-auto p-3 m-2">
                          <Card
                            className={
                              selectedId === skill_group[0].skill_group_id
                                ? "teal-light gold-border"
                                : "teal-light border-0"
                            }
                          >
                            <Card.Body>
                              <Card.Text
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setSelectedId(skill_group[0].skill_group_id)
                                }
                              >
                                <p
                                  className="d-inline m-2 "
                                  style={{ fontWeight: 700, color: "#163c5d" }}
                                >
                                  {
                                    skills_summary.find(
                                      (s) =>
                                        s.id === skill_group[0].skill_group_id
                                    )?.skill_title
                                  }
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    }
                  )}
                </Row>
                <Row>
                  <Col className="col-12 col-md-2 m-3">
                    <h5 style={{ borderBottom: "3px solid #23ceae" }}>
                      {
                        skills_summary.find((s) => s.id === selectedId)
                          ?.skill_title
                      }
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="border-0">
                      <Card.Body className="border-0">
                        <Card.Text>
                          {
                            skillData.filter(
                              (s) =>
                                s.question ==
                                skills_summary.find((s) => s.id === selectedId)
                                  ?.skill_title
                            )[0]?.subquestion
                          }
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default SkillProfileSection;
