import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Col, Row } from "react-bootstrap";

export const StepProgression = () => {
  return (
    <div className="w-75 mx-auto text-center justify-items-center">
      <Row>
        <Col>
          <p>
            In the next section, we will ask you about 20 skills that may be
            relevant to your role. We'll first ask you to confirm whether or not
            the skill is relevant, and if it is, we'll ask you to provide a self
            rating about your proficiency in that skill. You can view a list of
            the skills and their definitions
            <a href="#" style={{ color: "#ffb236" }}>
              here.
            </a>
          </p>
        </Col>
      </Row>
      <Row className="mx-auto w-75">
        <Col className="text-right">
          <span className="circle" style={{ backgroundColor: "green" }}>
            <FontAwesomeIcon icon={faCheck} color={"white"} />
          </span>
          <p>Step 1</p>
        </Col>
        <Col className="col-2 mx-auto">
          <hr className="w-100"></hr>
        </Col>
        <Col className="mx-auto">
          <span className="circle">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          <p>Step 2</p>
        </Col>
        <Col className="col-2 mx-auto">
          <hr></hr>
        </Col>
        <Col className="mx-auto">
          <span className="circle">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          <p>Step 3</p>
        </Col>
      </Row>
    </div>
  );
};
