import React, { useState } from "react";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import { Form, Badge, Card } from "react-bootstrap";
import { useEffect } from "react";
import "../../static/css/survey.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export const RangeQuestion = () => {
  const [value, setValue] = useState(1);

  const [label, setLabel] = useState("Beginner");

  const valMap = {
    1: (
      <>
        <h5>Beginner </h5>
        <br></br> Demonstrates Awareness of:{" "}
        <ul className="mt-2">
          <li>The roles, ceremonies and products used in SCRUM</li>
        </ul>
      </>
    ),
    2: (
      <>
        <h5>Intermediate </h5>
        <br></br> Demonstrates Intermediate Knowledge of:{" "}
        <ul className="mt-2">
          <li>The roles, ceremonies and products used in SCRUM</li>
        </ul>
      </>
    ),
    3: (
      <>
        <h5>Advanced </h5>
        <br></br>{" "}
        <p>
          Demonstrates Proficiency of:{" "}
          <ul className="mt-2">
            <li>The roles, ceremonies and products used in SCRUM</li>
          </ul>
        </p>
      </>
    ),
  };
  useEffect(() => {
    setLabel(valMap[value]);
  }, [value]);


  return (
    <>
      <Form.Range
        className="d-block mt-3 mb-2 custom-range w-75"
        value={value}
        max={3}
        min={1}
        onChange={(e) => setValue(e.target.value)}
      />
      <Form.Group>
        <Form.Label className="float-middle">
          <Card className="m-3 p-3 bg-light text-black denim-border">
            <FontAwesomeIcon
              className="center-icon-card"
              size={"xl"}
              style={value == 2 && { left: "200px" }}
              color={"#ffb236"}
              icon={faStar}
            />
            {value >= 2 && (
              <FontAwesomeIcon
                className="center-icon-card"
                style={{ left: "245px" }}
                size={"xl"}
                color={"#ffb236"}
                icon={faStar}
              />
            )}
            {value >= 3 && (
              <FontAwesomeIcon
                className="center-icon-card"
                style={{ left: "175px" }}
                size={"xl"}
                color={"#ffb236"}
                icon={faStar}
              />
            )}
            {label}
          </Card>
        </Form.Label>
      </Form.Group>
    </>
  );
};
