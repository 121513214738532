import React from "react";
import "./ToggleSwitch.scss";

const ToggleSwitch = ({ name, setChecked, checked }) => {
  const handleToggle = (e) => {
    let id = e.target.id;
    let update = checked.map((c) =>
      +c.label === +id
        ? {
            ...c,
            checked: e.target.checked,
            applicable: false,
            interested: false,
          }
        : c
    );

    setChecked(update);
  };

  const handleDirectClick = (value) => {
    let e = {
      target: {
        id: name,
        checked: value,
      },
    };
    handleToggle(e);
  };

  const isChecked =
    checked.filter((c) => c.label === name).length > 0 &&
    checked.filter((c) => c.label === name)[0]["checked"];

  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name="toggleSwitch"
        checked={
          isChecked === null || isChecked === undefined ? false : isChecked
        }
        onChange={(e) => handleToggle(e)}
        id={name}
      />
      <label className="toggle-switch-label" htmlFor={name}>
        
        <div
          className={`toggle-switch-inner yes  ${
            isChecked === null ? "unselected" : ""
          }`}
          onClick={() => handleDirectClick(false)}
        ></div>
        <div
          className={`toggle-switch-inner no ${
            isChecked === null ? "unselected" : ""
          }`}
          onClick={() => handleDirectClick(true)}
        ></div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
