import React from "react";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import Select from "react-select";

export const DropDownQuestion = ({ options, id }) => {
  const { setAnswer, requiredError, getExistingAnswer } = useSurveyProvider();

  const opts = options.map((o) => {
    return { label: o, value: o };
  });

  const existingIndex = opts.findIndex(
    (o) => o.label === getExistingAnswer(id - 1)
  );

  return (
    <div className="mx-auto w-75">
      <Select
	  	key={id}
        options={opts}
        onChange={(e) => setAnswer(e.value)}
        defaultValue={opts[existingIndex]}
        isInvalid={requiredError}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "max-content",
            minWidth: "50%",
            margin: "auto",
            marginTop: "2rem",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          bordderRadius: 0,
          colors: {
            ...theme.colors,
            primary50: "#819dad",
            primary25: "#D0D8DE",
            primary: "#163c5d",
          },
        })}
      />
    </div>
  );
};
