import React from "react";
import { Container, Row, Col, ButtonGroup, Button } from "react-bootstrap";
import SkillProfileSection from '../components/profile/ProfileSkills'

const ProfilePage = () => {
  return (
      <Container fluid className="vh-100 mb-5">
        <Row>
          <Col className="h-100 w-100 col-12">
              <SkillProfileSection />
          </Col>
        </Row>
      </Container>
  );
};

export default ProfilePage;
