import React, { useContext, useState, useEffect } from "react";
import { supabase } from "../lib/supabaseClient";

const AuthContext = React.createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState();
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true)

  useEffect(() => {

    const getAuth = async () => {
      await supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session);

        setTimeout(() => setLoading(false), 1000)
        // setLoading(false);
      });
  
      supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session);
      });
  
      setUser(session?.user ?? null);

    }

    getAuth();
   
  }, []);


  return (
    <AuthContext.Provider
      value={{
        user,
        session,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuthProvider() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
