import React, { useEffect } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import logo from "../../static/img/GM.png";

const StartingFrame = () => {
  const { handleNextButton, setAnswer, executeScroll } = useSurveyProvider();

  const handleClick = (e) => {
    e.preventDefault();
    handleNextButton();
    executeScroll();
  };

  useEffect(() => {
    executeScroll();
  }, []);

  // const handleRetakeClick = (e) => {
  //   e.preventDefault();
  //   // TODO set survey submitted to false
  //   setAnswer(null);

  //   handleNextButton();
  // };

  return (
    <Card
      className="h-100 mx-auto mb-2 mt-2 shadow-sm p-2 border-0 rounded-3 overflow-auto"
      //   style={{ height: "90%", width: "90%" }}
    >
      <Card.Header
        className="teal p-3 text-white border-0 rounded-0 d-flex text-thin"
        style={{ justifyContent: "space-between" }}
      >
        <div className="text-left align-content-center justify-content-center">
          <h2 className="text-thin mx-auto text-left justify-content-center">
            Let's Get Started Building Your Profile
          </h2>
        </div>
        <div className="text-right align-content-end">
          <img className="" width={50} src={logo} alt="GM logo"></img>
        </div>
      </Card.Header>
      <Card.Body className="p-3 ml-5 mr-5">
        <Row>
          <Col>
            <Card className="border-0 p-3 m-3 ">
              <Card.Header className="border-0 bg-transparent">
                <h3 className="text-center">Instructions</h3>
              </Card.Header>
              <Card.Body
                className="rounded-0"
                style={{ border: "3px solid #23ceae" }}
              >
                <Row>
                  <Col>
                    <p>
                      The SA3 Profile Builder enables you to baseline your
                      knowledge and skills in order to establish a solid
                      platform to launch your learning journey. The tool
                      captures your current proficiency, interest level and
                      relevance in skills related to your team function, role
                      and GM broadly.
                    </p>
                    <p>
                      The SA3 Profile Builder has been prepopulated with a range
                      of skills.{" "}
                    </p>
                    <p>
                      When evaluating your skills, please keep in mind that each
                      skill has six corresponding statements measuring different
                      dimensions of the skill. Each statement requires a
                      yes-or-no response. Please take the time to read each
                      statement carefully and answer honestly. If you possess
                      the skill, select "yes". If you do not possess the skill,
                      select "no". Please note that there are no right or wrong
                      answers, and these statements are designed to help assess
                      your current skill level.
                    </p>
                    <p>
                      Don't worry if you don't possess a specific skill. If
                      you are interested and want to learn more, you can let us
                      know at the end of the assessment.
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card
              className="p-3 m-3"
              style={{ border: "15px solid #23ceaf13" }}
            >
              <Card.Body>
                <h5 className="d-inline">Any Questions? </h5>
                <p className="d-inline">
                  If you have any technical questions, please reach out to
                  help@sa3.ec3co.com for assistance.{" "}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex bg-transparent border-0 text-right">
            <Button
              className="mx-auto btn-primary btn-lg float-right rounded-5 transition-button"
              onClick={handleClick}
            >
              Start <FontAwesomeIcon icon={faCirclePlay} />
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default StartingFrame;
