import React, { useEffect, useState } from "react";
import ToggleSwitch from "./ToggleSwitch";
import {
  Row,
  Col,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
} from "react-bootstrap";
import {
  faBan,
  faCheckCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import { CheckBox } from "./CheckBox";

export const ToggleQuestion = ({ id, subquestions }) => {

  const {
    setAnswer,
    getExistingAnswer,
    index,
    requiredError,
    surveyCompleted,
    skillData,
  } = useSurveyProvider();

  const [checked, setChecked] = useState([]);

  const Questions = skillData
    .filter((skills) => skills.id === id)[0]
    .subquestions.map((s) => {
      return { label: s.id, question: s.question };
    });

  const findReponseIndex = (label, param) => {
    let response = getExistingAnswer(id);
    if (response === null) return;

    let idx = response.findIndex((q) => q.label === label);
    if (idx > -1) return response[idx][param];
  };

  useEffect(() => {
    const checkedList = Questions.map((q) => {
      return {
        label: q.label,
        checked: findReponseIndex(q.label, "checked") ?? null,
        applicable: findReponseIndex(q.label, "applicable") ?? false,
        interested: findReponseIndex(q.label, "interested") ?? false,
      };
    });
    if (checkedList) {
      setChecked(checkedList);
    }

  }, [id]);

  useEffect(() => {

    if (index === id) setAnswer(checked);
  }, [checked]);

  return (
    <>
      <Container>
        <Row
          className="d-flex text-left align-items-left justify-content-start"
          style={{ background: "#f2f4f5" }}
        >
        </Row>
        <Row>
          <Col className="mt-3 w-100">
            {requiredError && (
              <Alert className="p-2 border-0" style={{ background: "#e27575", color: "white" }}>
                <FontAwesomeIcon className="mr-2 pr-3" icon={faWarning} />{"  "}
                Be sure to select either Yes or No for each statement prior to submitting your reponse for this skill.
              </Alert>
            )}
            <Row
              className="mt-1 mx-auto mb-4 d-none d-xl-flex"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <Col className="col-12 col-lg-10">
                <span style={{ fontSize: "12pt",  fontWeight: 600 }}>
                  Proficiency Statement
                </span>
              </Col>
              <Col
                className="col-12 col-lg-auto col-xxl-2"
                style={{ fontSize: "12pt", fontWeight: 600 }}
              >
                <p>I possess this skill</p>
              </Col>
              {/* <Col
                className="col-12 col-lg-auto"
                style={{ fontSize: "9pt", fontWeight: 700 }}
              >
                <p>Not Applicable</p>
              </Col>
              <Col
                className="col-12 col-lg-auto "
                style={{ fontSize: "9pt", fontWeight: 700 }}
              >
                <p>Interested</p>
              </Col> */}
            </Row>
            {Questions.map((q, i) => {
              return (
                <Row className="mt-1 mx-auto">
                  <Col className="col-12 col-lg-10">
                    <p className={requiredError && checked && checked?.filter(c => +c.label === +q.label)[0]['checked'] === null ? "required bold": ""}>{q.question}</p>
                  </Col>
                  <Col className="col-6 col-lg-2 text-start align-contents-start">
                    <ToggleSwitch
                      key={i}
                      name={q.label}
                      checked={checked}
                      setChecked={setChecked}
                    />
                  </Col>
                  {/* <Col className="float-left col-6 col-lg-2 ">
                    {!checked
                      .filter((c) => c.label === q.label)
                      .map((c) => c.checked)[0] && (
                      <CheckBox
                        key={i}
                        name={q.label}
                        checked={checked}
                        setChecked={setChecked}
                      />
                    )}
                  </Col> */}
                </Row>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
};
