import React, { useState } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import useProfileProvider from "../../contexts/ProfileContext";

export const ConfigureModal = ({ icon, text, styles }) => {
  const [show, setShow] = useState(false);
  const [userEnteredId, setUserEnteredId] = useState(null);

  const [loading, setLoading] = useState(false);

  const {
    id,
    setId,
    job_title,
    job_family,
    job_level,
	full_name, 
	setFullName,
    setJobTitle,
    setJobFamily,
    setJobLevel,
    onSubmitId,
	onUpdateProfile,
  setUpError,
  } = useProfileProvider();

  const [index, setIndex] = useState(0);
  const handleSelect = () => setIndex((index) => index + 1);

  const userSubmitId = (e) => {
    e.preventDefault();
    setLoading(true);
    onSubmitId(userEnteredId);
    setLoading(false);
    !loading && !setUpError && setShow(false);
  };

  const handleClose = () => {
    // if (!id && userEnteredId) {
    // 	setId(userEnteredId);
    // }
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="d-flex p-0 wrapper">
        <Button
          className={styles}
          style={{ border: "2px #23ceae !important", background: "#23ceae" }}
          onClick={handleShow}
        >
          {/* <FontAwesomeIcon icon={faGear} /> Configure */}
          {icon}
          {text}
        </Button>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size={"lg"}
        style={{ borderRadius: "0px !important" }}
      >
        <Modal.Header className="rounded-0 border-0" closeButton>
          <Modal.Title className="text-center">Employee Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rounded-0">
          {!id ? (
            <>
              <Row>
                <Col>
                  <Form.Group className="p-3 m-3">
                    <Form.Label>Please enter your employee ID (GMIN)</Form.Label>
                    <Form.Control
                      as="input"
                      placeholder="Employee ID"
                      onChange={(e) => setUserEnteredId(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="d-flex text-right">
                <Col className="text-end p-3">
                  <Button
                    className="mx-auto text-right login-button rounded-5"
                    onClick={userSubmitId}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col>
                <Alert className="sa p-2 m-3 border-0 text-black">
				Update your account details.
                </Alert>
                <Form.Group className="p-3 m-3">
                  <Row>
                    <Col>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        as="input"
                        defaultValue={full_name}
                        onChange={(e) => setFullName(e.target.value)}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Job Title</Form.Label>
                      <Form.Control
                        as="input"
                        defaultValue={job_title}
                        onChange={(e) => setJobTitle(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="col-8">
                      <Form.Label>Job Family</Form.Label>
                      <Form.Control
                        as="input"
                        defaultValue={job_family}
                        onChange={(e) => setJobFamily(e.target.value)}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Job Level</Form.Label>
                      <Form.Control
                        as="input"
                        defaultValue={job_level}
                        onChange={(e) => setJobLevel(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
          )}
       </Modal.Body>
	   {id &&  <Modal.Footer className="border-0">
            {/* <Button variant="secondary rounded-0" onClick={handleClose}>
              Close
            </Button> */}
            <Button
              variant="primary rounded-5 login-button"
              onClick={() => {onUpdateProfile(); setShow(false)}}
            >
              Submit
            </Button>
          </Modal.Footer>}
      </Modal>
    </>
  );
};
