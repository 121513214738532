import { useState } from "react";
import { supabase } from "../lib/supabaseClient";
import {
  Card,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Container,
} from "react-bootstrap";
import logo from "../static/img/sa3_logo.png";
import '../static/css/App.css'
export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOtp({ email });
      if (error) throw error;
      // alert('Check your email for the login link!')
    } catch (error) {
      // alert(error.error_description || error.message)
    } finally {
      setLoading(false);
      setSuccess(true);
    }
  };

  return (
    <Container className="vh-100 p-0 bg-lead sign-in-container" fluid>
      <Row className="h-75  mt-5 justify-content-md-center align-items-center">
        <Col className="col-lg-6 col-md-6 col-sm-4 col-xs-2 justify-content-md-center align-items-center">
          <Card className="mx-auto  h-100 border-0 bg-transparent  rounded-0">
            <Card.Header className="border-0 bg-transparent text-center text-white">
              <img className = "mb-2 img-fluid" src={logo} height={200}></img>
              {/* <h2 className="d-sm-inline mt-2" style={{ color: "#ffb236" }}>
                Profile Builder
              </h2> */}

              {!loading && !success && (
                <h4 className="mt-2"> Login or Sign-Up </h4>
              )}
            </Card.Header>
            <Card.Body className="align-items-center text-center text-white">
              {loading ? (
                <p className="lead">Sending sign in link...</p>
              ) : success ? (
                <>
                  <h5>Check your email! </h5>
                  <p className="lead">
                    {" "}
                    A link has been sent to {email}. Click on this link to sign
                    in!{" "}
                  </p>{" "}
                </>
              ) : (
                <Form onSubmit={handleLogin}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      className="login rounded-5"
                      type="email"
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* <Form.Text className="text-white">
                      We'll never share your email with anyone else.
                    </Form.Text> */}
                  </Form.Group>
                  <Button
                    className="mx-auto btn btn-lg border-0 rounded-5 login-button"
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
