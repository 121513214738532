import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import completeimage from "../../static/img/complete.png";
import "../../static/css/survey.css";
import { Link } from "react-router-dom";
import logo from '../../static/img/gm_blue.png'
const EndingFrame = () => {
  
  const { retakeSurvey, setsurveyCompleted } = useSurveyProvider();

  return (
    <Card
      className="vh-100 mb-2 mt-2 shadow-sm p-2 border-0 rounded-3"
      //   style={{ height: "90%", width: "90%" }}
    >
      <Card.Header className="bg-transparent border-0 rounded-0 d-flex">
        <h2 className="mx-auto text-left align-content-start"></h2>
        <div className="text-right align-content-end">
          <img className="" width={50} src={logo} alt="GM logo"></img>
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column align-items-center justify-content-center">
        <Card.Title className="text-center">
          <h3 className="d-inline ">
            Your assessment has been submitted!
          </h3>
          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </Card.Title>
        <Card.Subtitle className="m-3 p-2">
          <p className = "text-center lead">
            Your skills assessment has been completed. Navigate to your{" "}
            <Link to={"/profile"}>
              profile
            </Link>{" "}
            to view your results. 
            {/* Alternatively, you can click{" "}
            <Link to={"/assessment"} onClick={() => retakeSurvey()}>
              here
            </Link>{" "}
            to retake the skills assessment. */}
          </p>
          <p> 
            <em>You can not retake this assessment at this time. If you feel that your results are incorrect, please contact your Manager.</em>
          </p>
        </Card.Subtitle>
      </Card.Body>
    </Card>
  );
};

export default EndingFrame;
