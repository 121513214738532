import {
  faCheck,
  faHouse,
  faChevronLeft,
  faRightFromBracket,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Button } from "react-bootstrap";
import { useSurveyProvider } from "../../contexts/SurveyContext";
import Question from "./QuestionTypeSelector";
import "../../static/css/survey.css";
import logo from "../../static/img/gm_blue.png";
import ProgressBar from "./ProgressBar";
import Select from "react-select";
import { ShortAnswerQuestion } from "./ShortAnswerQuestion";

const QuestionFrame = ({ props }) => {
  const {
    handleSubmitAnswer,
    finalIndex,
    handleBackButton,
    skillData,
    index,
    setIndex,
    results,
    setAnswer,
    percent,
    answer,
    setRequiredError,
    disabledButton
  } = useSurveyProvider();

  // const [options, setOptions] = useState([])

  // const handleSubmitFinal = () => {
  //   handleSubmitAnswer();
  // };

  const isNotAnswered = (id) => {
    let res = results;
    let idx = res.findIndex((ele) => ele.id === id);
    let prevIdx = res.findIndex((ele) => ele.id === (id - 1)) 
    return prevIdx > 0 ? false : idx < 0
  }

  const opts = skillData.map((skill) => {
    return {
      label: skill.question,
      value: skill.id,
      disabled: isNotAnswered(skill.id) 
    };
  });



  const handleSelectQuestion = (val) => {
    if (!isNotAnswered(props.id)) { 
      handleSubmitAnswer(props)
    }
    if (isNotAnswered(props.id) && answer) { 
      let skill_array = skillData?.filter(s => s.id === props.id)[0].subquestions.map(sq => sq.id)
      let answer_array = answer?.map(a => a.label) 
      if (answer_array.toString() === skill_array.toString()) {
        handleSubmitAnswer(props)
      } 
    }
    setAnswer(null); 
    setIndex(val);
    setRequiredError(false)
    
  }


  return (
    <Card
      className="h-100 mx-auto mb-2 mt-2 shadow p-2 border-0 rounded-3"

      //  style={{ width: "90%", height: "90%" }}
    >
      <Card.Header
        className="d-flex border-0 bg-transparent "
        style={{ justifyContent: "space-between" }}
      >
        <div className="mt-3 float-left clickable">
          <FontAwesomeIcon
            color={"#163c5d"}
            onClick={() => handleBackButton()}
            icon={faChevronLeft}
          />
        </div>

        <div className=" mt-3 float-right">
          <img width={50} src={logo}></img>
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column align-items-center justify-content-center">
        <ProgressBar completed={percent} bgcolor={"#23ceae"} />
        <Card.Title className="align-items-center">
          {/* <h3
            className="d-inline"
            style={{ borderBottom: "3px solid #23ceae" }}
            // style={{
            //   background: "#163c5d",
            //   color: "white",
            //   padding: ".5rem",
            //   borderRadius: "5px",
            // }}
          >
            {props.question}
          </h3> */}
          <Select
            key={index}
            options={opts}
            onChange={(e) => handleSelectQuestion(e.value)}
            defaultValue={opts[props.questionid - 1]}
            isOptionDisabled={(o) => o.disabled}
            // isInvalid={requiredError}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: "max-content",
                minWidth: "200px",
                margin: "auto",
                marginTop: "2rem",
                borderBottom: "3px solid #23ceae !important",
                borderTop: "0px",
                borderRight: "0px",
                borderLeft: "0px",
                fontSize: "18pt !important",
                fontFamily: "Barlow !important",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              minWidth: "50%",
              border: "none !important",
              borderColor: "transparent",
              borderBottom: "3px solid #23ceae !important",
              fontSize: "18px",
              fontFamily: "Barlow !important",
              colors: {
                ...theme.colors,
                neutral80: '#18242f',
                primary50: "#819dad",
                primary25: "#D0D8DE",
                primary: "#23ceae",
              },
            })}
          />
        </Card.Title>
        <Card.Subtitle className={props.subquestion ? "w-75 m-1 p-1" : ""}>
          {props.subquestion}
        </Card.Subtitle>
        {Question(props)}
        <Button
          className="btn-lg mt-5 rounded-5 transition-button"
          onClick={() => handleSubmitAnswer(props)}
          onKeyDown={(e) => e.key === "Enter" && handleSubmitAnswer(props)}
          disabled={disabledButton}
        >
          <span>NEXT</span> <FontAwesomeIcon icon={faRightLong} />{" "}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default QuestionFrame;
